.site-company{
	.localnav{
		border-bottom: $border 4px solid;
		&__list{
			border-left: $border 1px solid;
			@include flex();
			li{
				width: 25%;
				text-align: center;
				border-right: $border 1px solid;
				@media screen and (max-width: $phone){
					width: 50%;
					border-bottom: $border 1px solid;
					&:nth-child(2n+2){
						border-right: 0;
					}
				}
				a{
					display: block;
					color: $normal;
					font-size: .92rem;
					padding: 32px 0;
					line-height: 1;
					position: relative;
					@media screen and (max-width: $tab){
						padding: 24px 0;	
					}
					&:before{
						content: attr(data-eng);
						font-size: 1.12rem;
						font-weight: 700;
						display: block;
						margin: 0 0 6px;
					}
					&:after{
						content: '';
						width: 0;
						height: 4px;
						background: $base;
						position: absolute;
						left: 50%;
						bottom: -4px;
						transform: translateX(-50%);
						transition: width 200ms $ease;
					}
					&:hover{
						color: $base;
						&:after{
							width: 100%;
						}
					}
				}
			}
		}
	}
	
	.dataset{
		margin: 20px 0 0;
		&.not{
			margin-top: 0;
		}
		dt{
			font-size: .92rem;
			background: $base;
			color: #FFF;
			display: inline-block;
			padding: 1px 24px;
			margin: 0 0 6px;
		}
		&__str{
			color: $base;
			display: inline-block;
		}
	}
	
	.ceo{
		background: #FFF;
		padding: 32px;
		@include flex();
		align-items: center;
		@media screen and (max-width: $phone){
			padding: 15px;
			display: block;
		}
		&__pic{
			width: 480px;
			@media screen and (max-width: $tab){
				width: 50%;	
			}
			@media screen and (max-width: $phone){
				width: auto;	
			}
			img{
				width: 100%;
			}
		}
		&__data{
			width: calc(100% - 480px);
			padding: 0 0 0 64px;
			line-height: 1;
			position: relative;
			@media screen and (max-width: $tab){
				width: 50%;
				padding: 0 0 0 56px;
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 24px 0 0;
				text-align: center;
			}
			dt{
				font-size: 1.24rem;
			}
			dd{
				&.name{
					font-size: 4.8rem;
					margin: 32px 0 12px;
					text-indent: -.1em;
					@media screen and (max-width: $tab){
						font-size: 3.2rem;
						margin: 24px 0 8px;
					}
				}
				&.eng{
					font-size: 1rem;
					letter-spacing: .4em;
				}
			}
		}
		&__message{
			width: 100%;
			padding: 40px 0 0;
			@media screen and (max-width: $phone){
				padding: 24px 0 0;	
			}
			h3{
				font-weight: 700;
				font-size: 1.56rem;
				text-align: center;
				background: $gray;
				padding: 20px 0;
				@media screen and (max-width: $phone){
					font-size: 1.24rem;	
				}
			}
			&__txt{
				padding: 40px 64px;
				border: $gray 2px solid;
				border-top: 0;
				font-size: 1.12rem;
				line-height: 2;
				@media screen and (max-width: $phone){
					padding: 20px;
					font-size: 1rem;
				}
				p{
					margin: 0 0 2rem;
					text-align: justify;
					line-height: 2;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
				&__name{
					margin: 64px 0 0;
					text-align: right;
					@media screen and (max-width: $phone){
						margin: 24px 0 0;	
					}
					img{
						width: 320px;
						@media screen and (max-width: $phone){
							width: 240px;	
						}
					}
				}
			}
		}		
	}
	
    .listitem{
		background: #FFF;
		padding: 32px;
		margin: 48px 0 0;
		@media screen and (max-width: $phone){
			padding: 15px;
			margin: 32px 0 0;
		}
		&__box{
			@include flex();
			margin: 32px 0 0;
			@media screen and (max-width: $phone){
				display: block;
				margin: 24px 0 0;
			}
			&:first-of-type{
				margin: 0;
			}
			&__pic{
				width: 416px;
				@media screen and (max-width: $tab){
					width: 240px;	
				}
				@media screen and (max-width: $phone){
					width: auto;	
				}
				&.rvs{
					order: 2;
				}
				img{
					width: 100%;
				}
			}
			&__data{
				width: calc(100% - 416px - 48px);
				@include flex();
				justify-content: flex-start;
				align-items: center;
				border-bottom: $border 1px solid;
				@media screen and (max-width: $tab){
					width: calc(100% - 240px - 48px);
					border-bottom: 0;
				}
				@media screen and (max-width: $phone){
					width: auto;
					display: block;
				}
				&.rvs{
					order: 1;
				}
				dt{
					font-size: 2.24rem;
					line-height: 1;
					font-weight: 700;
					margin: 0 0 28px;
					@media screen and (max-width: $tab){
						font-size: 1.66rem;
						margin: 0 0 16px;
					}
					@media screen and (max-width: $phone){
						text-align: center;
						padding: 24px 0 0;
					}
				}
				dd{
					&.str{
						font-size: 1.32rem;
						font-weight: 700;
						@media screen and (max-width: $tab){
							font-size: 1.12rem;	
						}
						@media screen and (max-width: $phone){
							font-size: 1.08rem;
							text-align: center;
						}
					}
				}
				.numb{
					li{
						font-size: 1.24rem;
						padding: 0 0 0 36px;
						margin: 0 0 10px;
						position: relative;
						@media screen and (max-width: $tab){
							font-size: 1.12rem;
							margin: 0 0 8px;
						}
						@media screen and (max-width: $phone){
							padding-bottom: 8px;
							border-bottom: $border 1px solid;
						}
						&:last-child{
							margin: 0;
							@media screen and (max-width: $phone){
								border: 0;
								padding-bottom: 0;
							}
						}
						@for $i from 1 through 3{
							&:nth-child(#{$i}){
								&:before{
									content: '#{$i}';
									font-size: .88rem;
									width: 24px;
									height: 24px;
									background: $base;
									color: #FFF;
									text-align: center;
									position: absolute;
									top: 50%;
									left: 0;
									transform: translateY(-50%);
									@include flex();
									align-items: center;
									justify-content: center;
								}
							}
						}
					}
				}
			}
            .cent{
				padding: 32px 0 0;
				@media screen and (max-width: $tab){
					width: 100%;	
				}
                dt{
                    font-size: 2.24rem;
					font-weight: 700;
                    line-height: 1;
                    text-align: center;
					@media screen and (max-width: $tab){
						font-size: 1.66rem;	
					}
                }
				dd{
					margin: 24px 0 0;
					.numb{
						@include flex();
						@media screen and (max-width: $tab){
							display: block;
						}
						li{
							font-size: 1.12rem;
							width: calc(33.3% - 10px);
							background: $gray;
							padding: 24px;
							padding-left: 72px;
							position: relative;
							line-height: 1.66;
							@media screen and (max-width: $tab){
								width: auto;
								margin: 0 0 8px;
								&:last-child{
									margin: 0;
								}
							}
							@media screen and (max-width: $phone){
								padding: 15px;
								padding-left: 52px;
							}
                            @for $i from 1 through 3{
                                &:nth-child(#{$i}){
                                    &:before{
                                        content: '#{$i}';
                                        font-size: .88rem;
                                        width: 24px;
                                        height: 24px;
                                        background: $base;
                                        color: #FFF;
                                        text-align: center;
                                        position: absolute;
                                        top: 50%;
                                        left: 24px;
                                        transform: translateY(-50%);
                                        @include flex();
                                        align-items: center;
                                        justify-content: center;
										@media screen and (max-width: $phone){
											left: 15px;	
										}
                                    }
                                }
                            }

						}
					}
				}
            }
		}
    }

	
	.historylist{
		position: relative;
		&:before{
			content: '';
			width: 2px;
			height: 100%;
			position: absolute;
			left: 205px;
			top: 0;
			background: $base;
			@media screen and (max-width: $phone){
				left: 124px;	
			}
		}
		> li{
			list-style: none;
			margin: 0 0 24px;
			padding: 0 0 24px;
			border-bottom: $border 1px solid;
			@media screen and (max-width: $phone){
				margin: 0 0 16px;
				padding: 0 0 16px;
			}
			&:last-child{
				margin: 0;
			}
			dl{
				font-size: 1.04rem;
				@include flex();
				dt{
					width: 240px;
					font-weight: 700;
					@media screen and (max-width: $phone){
						width: 120px;	
					}
				}
				dd{
					width: calc(100% - 240px);
					padding: 0 0 0 40px;
					position: relative;
					@media screen and (max-width: $phone){
						width: calc(100% - 120px);
						padding: 0 0 0 40px;
					}
					&:before{
						content: '';
						width: 14px;
						height: 14px;
						background: $base;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						left: -41px;
						transform: translateY(-50%);
						@media screen and (max-width: $phone){
							width: 10px;
							height: 10px;
							left: 0;
						}
					}
				}
			}
		}
	}
	.access{
		@include flex();
		justify-content: flex-start;
		margin: 0 0 0 -24px;
		@media screen and (max-width: $phone){
			display: block;
			margin: 0;
		}
		&__item{
			width: 33.3%;
			padding: 0 0 0 24px;
			margin: 24px 0 0;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
				margin: 0 0 12px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			&:nth-child(-n+3){
				margin-top: 0;
			}
			dl{
				background: #FFF;
				height: 100%;
				padding: 24px;
				box-shadow: 0 0 24px rgba($normal, .08);
				position: relative;
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 12px 12px 0 0;
					border-color: $base transparent transparent transparent;
					position: absolute;
					top: 8px;
					left: 8px;
				}
			}
			dt{
				font-size: 1.16rem;
				font-weight: 700;
				margin: 0 0 8px;
				padding: 0 0 8px;
				border-bottom: $border 1px solid;
			}
			dd{
				font-size: .92rem;
				line-height: 1.66;
			}
		}
	}
	.map{
		iframe{
			width: 100%;
			height: 400px;
			vertical-align: bottom;
			filter: grayscale(1);
		}
	}
	
	.tableinclum{
		@include flex();
		justify-content: flex-start;
		@media screen and (max-width: $phone){
			display: block;	
		}
		li{
			width: 50%;
			@media screen and (max-width: $phone){
				width: auto;	
			}
		}
	}
	.mb{
		margin-bottom: 20px;
		@media screen and (max-width: $phone){
			margin-bottom: 16px;	
		}
	}
}